.crm {
    background-color: #fff;
    border-radius: 3px;
    height: 100%;
    width: 100%;

    .flex {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media (max-width: 900px) {
            flex-direction: column;
        }
    }

    .tab {
        font-weight: bold;
    }

    .header-list {
        margin: 20px;
    }

    .main {
        margin: 20px;
    }

    .crm-info {
        &__text {
            font-size: 1rem;
            color: black;
            font-weight: normal;
        }

        &__row {
            display: flex;
            align-items: center;
        }

        &__row-settings {
            margin-left: 10px;
            font-size: 1.2rem;
            color: #646c9a;
        }

        &__cell {
            width: 340px;
            display: flex;
            justify-content: flex-start;
            padding: 10px;
            align-items: center;
            font-size: 1.1rem;

            &:first-child {
                font-weight: bold;
            }
        }

        &__checkbox-cell {
            padding-left: 0;
        }

        &__wide-cell {
            width: 50px;
        }

        &__limit-cell {
            font-size: 1.5rem;
            color: #738bff;
            font-weight: bold;
        }
    }

    .button {
        font-size: 1rem;
    }

    .pointer {
        cursor: pointer;
    }

    .form-wrapper {
        display: flex;
        justify-content: space-between;

        @media (max-width: 600px) {
            flex-direction: column;
        }
    }

    .form {
        width: 80%;
        display: flex;

        @media (max-width: 600px) {
            flex-direction: column;
        }

        &__container {
            display: flex;
            margin-right: 30px;
            height: 70px;
        }

        &__title {
            margin: 8px 15px 0 0;
            font-size: 1.2rem;
        }
    }

    .MuiOutlinedInput-input {
        padding: 5px 14px !important;
    }

    .picker {
        margin-top: 0 !important;
    }

    .search {
        width: auto;
        min-width: 150px;
        height: 70px;
        display: flex;
        justify-content: flex-end;

        .MuiOutlinedInput-input {
            padding: 12px !important;
        }

        .MuiFormControl-marginNormal {
            margin-top: 0 !important;
        }
    }

    .block {
        width: 350px;
        height: 250px;
        border-radius: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        margin: 0 30px 20px 0;
        padding: 20px;

        @media (max-width: 1500px) {
            width: 300px;
        }

        @media (max-width: 1100px) {
            width: 100%;
            margin-right: 0;
            height: auto;
        }

        &__title {
            color: #5d78ff;
        }

        &__row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
        }

        &__subtitle {
            color: #595d6e;
            font-weight: bold;
            margin-bottom: 5px;
        }

        &__text {
            color: grey;
            height: 40px;
        }
    }

    .top-buttons {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .pointer {
        cursor: pointer;
    }

    .cell-25 {
        width: 25%;
    }
}

.crm-dropdown {
    height: 70px;

    .MuiInputLabel-outlined {
        top: 4px !important;
    }

    .MuiInputLabel-outlined.Mui-focused {
        top: 2px !important;
    }

    .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
        .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        height: 50px;
    }

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        padding: 0;
    }
}
