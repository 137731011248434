.generated-report-details {

    .buttons-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .header {
        font-size: 25px;
        font-weight: 500;
    }

    .buttons-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
    }


    .toolbarm {
        display: flex;
        align-items: center;

        &-elem {
            border-radius: 4px;
            padding: 3px;
            height: 25px;
            width: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &.active {
                background: #e5e9f0;
            }
        }
    }
}

.generated-report-details.kt-portlet .kt-portlet__body {
    padding-top: 0;
}
