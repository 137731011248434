.create-form {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
    border-radius: 4px;
    height: calc(100vh - 175px);
    overflow-y: auto;

    &__page-title {
        font-size: 20px;
        font-weight: 600;
        margin: 25px 0 0 25px;
    }

    &__form-header {
        padding: 25px 25px 0 25px;
    }

    &__form-footer {
        padding: 0 25px 25px 25px;
    }

    .MuiAutocomplete-input {
        padding: 0 !important;
    }
}
