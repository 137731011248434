.report-modal {
  height: 400px;

  &__close-btn {
    cursor: pointer;
  }

  &__title {
    padding: 32px;
    color: #656d97;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    border-bottom: 1px solid #ebedf2;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__wrap {
    padding: 20px 32px 0 32px;
    height: 300px;
    overflow-y: scroll;
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    color: #4d5e93;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    border-bottom: 1px solid #ebedf2;

    &__item {
      padding: 0 10px;

      &.select {
        width: 60px;
      }

      &.type {
        width: 237px;
      }
    }
  }

  .main {
    &__checkbox {
      border: 1px solid #757575;
      border-radius: 50%;
      width: 14px;
      height: 14px;

      &.active {
        border: 4px solid #3e6df6;
      }
    }
    &__item {
      display: flex;
      align-items: center;
      height: 56px;
      padding: 0 0 0 10px;
      color: #4d5e93;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: 'Poppins';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      border-bottom: 1px solid #ebedf2;

      &:hover {
        background: #f8f8fa;
        cursor: pointer;
      }

      &.select {
        min-width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 0 -9px;
      }

      &.type {
        width: 237px;
      }
    }
  }

  &__btnblock {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 18px 32px 32px 32px;
  }

  &__cancel {
    color: #2a2a2b;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-radius: 4px;
    background: #e6e9ef;
    padding: 8px 16px;
    margin: 0 16px 0 0;
    cursor: pointer;
  }
  &__open {
    color: #fff;
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border-radius: 4px;
    background: #3e6df6;
    padding: 8px 16px;
    cursor: pointer;

    &.disabled {
      background: #a6b6e4;
      cursor: context-menu;
    }
  }
}

.modal-content {
/*  width: 600px !important;*/
  top: 86px !important;
}
