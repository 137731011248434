.custom-timepicker {
  &__error-text {
    color: #fd397a;
    font-size: 0.75rem;
    margin-top: 3px;
    text-align: left;
    font-family: Poppins;
    font-weight: 400;
    line-height: 1.66;
    margin-left: 6px;
    margin-right: 4px;
  }
  .picker-wrap {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.custom-timepicker__error-border {
  .picker {
    border: 1px solid #fd397a !important;
  }
  &__error-text{
    margin: 0;
  }
}
