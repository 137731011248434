.task-modal {
    .close-button {
        position: absolute;
        top: 0;
        right: 20px;
        cursor: pointer;
        color: grey;
        font-size: 2.5em;
    }

    .title {
        margin: 20px 0 0 20px;
    }

    .row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin: 0 0 5px 0;
        width: 100%;
        height: 65px;

        &__elem {
            width: 47%;

            & > div {
                margin: 0;

                .picker {
                    height: 43px;
                }
            }
        }
    }

    .picker {
        margin: 0 !important;
    }

    .MuiOutlinedInput-input {
        padding: 14.5px 14px !important;
    }

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        min-height: 43px;
        padding: 0px !important;
        background-color: white;
    }
}
