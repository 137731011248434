.view-exercise {
  padding: 25px;

  .modal-header {
    padding: 0;
  }

  .modal-body {
    padding: 0;
  }

  .modal-footer {
    padding: 0;
  }

  &__close-button {
    position: absolute;
    top: 9px;
    right: 20px;
    cursor: pointer;
    color: grey;
    font-size: 2.5em;
  }

  &__page-title {
    font-size: 18px;
    font-weight: 700;
    margin-right: 30px;
    margin-bottom: 20px;
  }

  &__checkbox-title {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
  }

  &__checkboxes {
    margin-bottom: 15px;
    margin-left: -10px;
    display: flex;
  }

  &__textfield {
    width: 100%;
    margin-bottom: 20px;
  }

  &__textarea {
    width: 100%;
    margin-bottom: 16px;

    .MuiOutlinedInput-input {
      height: 100px !important;
    }
  }

  &__dropdown {
    height: 70px;

    .MuiAutocomplete-input {
      padding: 5px !important;
    }
  }

  &__video-container {
    position: relative;
    height: 303px;
    border: 1px solid #c7c8d4;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    background-color: #000000;
    & .overlay-image {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
      object-fit: contain;
      background-color: white;
    }
  }

  &__description-wrapper {
    margin-bottom: 20px;
  }

  &__description-title {
    font-size: 16px;
    font-weight: 600;
    color: #000000bc;
  }

  &__description-text {
    font-size: 16px;
    color: #000000bc;
    margin: 0;
  }

  &__data-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__data-block {
    margin-bottom: 20px;
  }

  &__data-block-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #000000bc;
  }

  &__data-block-text {
    font-size: 16px;
    color: #000000bc;
  }
}
