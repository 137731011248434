.create-workout-exercise-table {
    padding-top: 15px;

    @media screen and (max-width: 450px) {
        overflow-x: scroll;
    }

    .create-workout-exercise-table-content {
        display: grid;
        width: fit-content;
        max-width: 100%;
        grid-gap: 15px;

        @media screen and (max-width: 450px) {
            &.overcrowded {
                width: 440px;
                max-width: unset;
            }
        }
    }

    &__head, &__row  {
       display: contents;
    }

    &__head-elem {
        color: #000000bc;
        font-size: 12px;
        position: relative;
        cursor: pointer;
        white-space: nowrap;
    }

    &__head-elem-arrow {
        font-size: 14px;
        font-weight: 600;
        margin-left: 5px;
        cursor: pointer;
        transform: rotate(-90deg);
        transition: 0.2s;

        &_active {
            transform: rotate(90deg);
            transition: 0.2s;
        }
    }

    &__head-select {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        background-color: #fff;
        border: 1px solid darkgray;
    }

    &__head-select-elem {
        cursor: pointer;
        padding: 5px;
        background-color: #fff;
        white-space: nowrap;

        &:hover {
            background-color: lightgrey;
        }
    }

    &__row-elem {
        height: 35px;
        width: 100%;
    }

    &__row-elem-input {
        width: 100%;
        border: 1px solid #c4c4c4;
        border-radius: 4px;
        height: 23px;
    }

    .picker-wrap {
        margin: 0;

        .picker {
            height: auto;
        }
    }

    .exercise-set_status button, .exercise-set_delete-btn, .exercise-set_status {
        display: flex;
    }

    .exercise-set_status button, .exercise-set_delete-btn {
        border: none;
        appearance: unset;
        background: unset;
        padding: 0;
        svg {
            margin-right: 5px;
        }
    }

    .exercise-set_status button {
        transition: all .2s ease;
        color: #808080;
        margin-right: 5px;

        &:hover {
            color: #484848;
        }
        &.completed {
            color: #007AFF;

            &:hover {
                color: #004999;
            }
        }
    }
}
