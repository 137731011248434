.login {
    min-height: 100vh;
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;

    button.btn.btn-login {
        width: 100%;
        text-align: center;
        padding: 7px 21px;
        color: #fff !important;
        background-color: #5d78ff;
        font-family: "Raleway";
    }

    &-top {
        background-color: #ff722c; //#f44719;
        color: #ffffff;
        text-align: center;
        font-size: 18px;
        font-family: "Hind";
        font-weight: 600;
        padding: 10px;
        font-family: "Hind", sans-serif;
    }

    &-main {
        padding: 30px;
        background-color: #fff;
    }

    &-logo {
        width: 250px;
        height: 45px;
        object-fit: contain;
        margin: 30px;
    }

    &-wrap {
        margin: 0 20px;
        width: 400px;
        box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        background: rgba(255, 255, 255, 0.65);
        overflow: hidden;
        font-family: "Raleway", sans-serif;

        label,
        input,
        .MuiFormControlLabel-root .MuiTypography-body1,
        .MuiFormControlLabel-root .MuiTypography-body1 .kt-link {
            font-family: "Raleway";
        }

        .MuiTypography-body1,
        .kt-link,
        button.btn.btn-login,
        .MuiInputBase-input {
            font-size: 1rem !important;
            font-family: "Raleway", sans-serif;
        }

        .MuiFormHelperText-contained,
        .MuiInputLabel-formControl {
            font-family: "Raleway", sans-serif;
        }

        @media screen and (max-width: 500px) {
            width: auto;
        }

        &.forgot {
            @media screen and (max-width: 500px) {
                width: calc(100% - 40px) !important;
            }
        }
    }
}

.registration {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 0 18px 36px;

    @media screen and (max-width: 800px) {
        flex-wrap: wrap;
    }

    &-top {
        padding: 36px 18px 0;

        &__h1 {
            color: #ff722c;
            font-weight: 700;
            font-size: 45px;
            text-align: center;
            font-family: "Hind";

            @media screen and (max-width: 800px) {
                font-size: 26px;
            }
        }

        &__h3 {
            color: #212121;
            font-size: 30px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 80px;
            font-family: "Hind";

            @media screen and (max-width: 800px) {
                font-size: 18px;
                margin-bottom: 35px;
            }
        }
    }

    &-form {
        width: 50%;
        max-width: 600px;
        overflow: hidden;
        background-color: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 4px;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
        margin-right: 30px;

        label,
        input,
        .MuiFormControlLabel-root .MuiTypography-body1,
        .MuiFormControlLabel-root .MuiTypography-body1 .kt-link {
            font-family: "Raleway";
        }

        form {
            padding: 30px 30px 30px;
        }

        @media screen and (max-width: 800px) {
            width: 100%;
            margin-right: 0;
        }
    }

    &-info {
        width: 50%;
        max-width: 600px;
        margin-left: 30px;
        @media screen and (max-width: 800px) {
            width: 100%;
            margin-top: 30px;
        }

        @media screen and (max-width: 500px) {
            width: 100%;
            margin-right: 0;
            margin-left: 0;
        }

        &__wrap {
            display: flex;
            width: 100%;
            padding-bottom: 30px;
            background-color: white;

            @media screen and (max-width: 500px) {
                flex-direction: column;
                align-items: center;
            }
        }

        &__img {
            height: 165px;
            width: 165px;
            object-fit: contain;
            margin-right: 30px;

            @media screen and (max-width: 500px) {
                margin-right: 0;
            }
        }

        &__text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            margin: 20px 0;

            &-top {
                font-size: 14px !important;
                font-weight: 400;
                color: #373737;
                font-family: "Raleway";

                @media screen and (max-width: 500px) {
                    text-align: center;
                }
            }

            &-autor {
                font-size: 14px !important;
                font-weight: 400;
                color: grey;
                font-family: "Raleway";

                @media screen and (max-width: 500px) {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    &-btn {
        display: flex;
        justify-content: center;

        button.btn.btn-primary {
            font-family: "Raleway";
            color: #fff !important;
            background-color: #5d78ff;
            padding: 7px 21px;
            width: 100%;
        }
    }
}

.btn-blue {
    background-color: #3669ff !important;
}

.texfield-email > div > div {
    width: 100%;
}
