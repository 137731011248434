.calendar-component {
  width: 100%;
  margin-top: 20px;
  position: relative;
  overflow-x: auto;
  transform: rotateX(180deg);
  -ms-transform: rotateX(180deg); /* IE 9 */
  -webkit-transform: rotateX(180deg); /* Safari and Chrome */

  .calendar-component-content {
    width: 100%;
    transform: rotateX(180deg);
    -ms-transform: rotateX(180deg); /* IE 9 */
    -webkit-transform: rotateX(180deg); /* Safari and Chrome */

    overflow-y: auto;

    /* chrome, safari */
    &::-webkit-scrollbar {
      width: 0;
    }

    /* ie 10+ */
    -ms-overflow-style: none;

    height: calc(100vh - 400px);

    @media (max-width: 890px) {
      width: 800px;
    }
  }

  .calendar-component-content-profile {
    @media (max-width: 450px) {
      height: 280px;
    }
  }

  .calendar-component-content-dashboard {
    height: 600px;
  }

  .head {
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    font-size: 14px;
    font-weight: 600;

    transform: rotateX(180deg);
    -ms-transform: rotateX(180deg); /* IE 9 */
    -webkit-transform: rotateX(180deg); /* Safari and Chrome */

    &__elem {
      border-right: 1px solid lightgrey;
      border-bottom: 1px solid lightgrey;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 100px;
    }

    &__elem-first {
      border-right: 1px solid lightgrey;
      border-bottom: 1px solid lightgrey;
      min-width: 100px;
    }
  }

  .calendar-row {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(8, 1fr);

    &__elem {
      border-right: 1px solid lightgrey;
      border-bottom: 1px solid lightgrey;
      height: 60px;
      display: flex;
      padding: 10px;
      min-width: 100px;
    }
  }

  .current {
    background-color: #eef1ff;
  }

  .picker {
    margin: 0;
  }

  .calendar-body {
    top: 0;
    left: 12.5%;
    position: absolute;
    height: calc(100% - 60px);
  }

  .event {
    position: absolute;
    background-color: #a2a3b7;
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid #fff;
    overflow: hidden;
    font-size: 10px;

    &:hover {
      background-color: #b1b2c8;
    }

    &__title {
      width: 100%;
      text-align: right;
      color: #fff;
      padding: 0 3px;
      text-overflow: ellipsis;
    }

    &__time {
      padding: 0 3px;
      width: 100%;
      text-align: right;
      color: #fff;
      text-overflow: ellipsis;
    }
  }
}

.calendar-page {
  .new-calendar__table-container {
    overflow: auto;
    position: relative;
    width: 100%;
    height: calc(100vh - 200px);

    @media screen and (max-width: 767px) {
      height: calc(100vh - 150px);
    }

    // &.new-calendar__table-container-profile {
    //   @media (max-width: 450px) {
    //     height: 280px;
    //   }
    // }

    .calendar-body {
      top: 60px;
      left: 12.5%;
      position: absolute;
      height: calc(100%);
    }

    .event {
      position: absolute;
      background-color: #a2a3b7;
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid #fff;
      overflow: hidden;
      font-size: 10px;

      &:hover {
        background-color: #b1b2c8;
      }

      &__title {
        width: 100%;
        text-align: right;
        color: #fff;
        padding: 0 3px;
        text-overflow: ellipsis;
      }

      &__time {
        padding: 0 3px;
        width: 100%;
        text-align: right;
        color: #fff;
        text-overflow: ellipsis;
      }
    }

    table {
      width: 100%;

      th {
        border-right: 1px solid lightgrey;
        border-bottom: 1px solid lightgrey;
        height: 60px;
        text-align: center;
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 100px;
        position: sticky;
        top: 0;
        z-index: 9;
        background-color: #f4f4f4;
      }
      th:first-child {
        left: 0;
        z-index: 10;
      }
      td {
        border-right: 1px solid lightgrey;
        border-bottom: 1px solid lightgrey;
        height: 60px;
        padding: 10px;
        min-width: 100px;
      }
      td:first-child {
        position: sticky;
        left: 0;
        z-index: 9;
        background-color: #f4f4f4;
      }
    }
  }

  .current {
    background-color: #eef1ff;
  }

  .subheader {
    margin-bottom: 20px;
  }
}