.add-to-program-modal-dropdown {
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        padding: 0 !important;
    }
}

.adding-to-program-progress {
    overflow: hidden;
    margin-left: 20px;
    height: 12px;
    width: 90%;
    border-radius: 15px;
    background-color: #f5f5f5;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);

    &__value {
        height: 100%;
        border-radius: 15px;
        background-color: #5d78ff;
    }
}

.adding-to-program-count {
    display: flex;
    margin: 20px;
    gap: 50px;

    &__text {
        font-size: 16px;
        font-weight: 500;
    }

    &__err-text {
        font-size: 16px;
        font-weight: 500;
        color: red;
    }
}