.task-list-page {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  padding: 25px;
  border-radius: 4px;

  &__sort-container {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1380px) {
      flex-wrap: wrap;
    }
  }

  &__sort-buttons {
    display: flex;
    margin: 0 0 20px 0;
    position: relative;
    width: 100%;

    @media (max-width: 1200px) {
      flex-wrap: wrap;
      margin: 0;
    }
  }

  &__sort-input {
    margin: 0 10px 0 0;
    position: relative;
    z-index: 3;
    min-height: 43px;

    &:first-child {
      order: 1;
      margin-left: auto;

      @media screen and (max-width: 767px) {
        order: 0;
      }
    }

    @media (max-width: 1200px) {
      width: 250px;
      margin: 0;
    }
  }

  &__pagination {
    display: flex;
    justify-content: space-between;
  }

  .MuiTab-wrapper {
    font-size: 12px;
    font-weight: 700;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 440px) {
      flex-direction: column;
      align-items: start;
      margin-bottom: 10px;
    }
  }

  .MuiOutlinedInput-root {
    height: 43px;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    width: 150px;
    min-height: 43px;
    padding: 0 0 0 4px !important;
    height: auto;
    background-color: #fff;
  }

  .picker {
    height: 43px !important;
    width: 140px;
    margin: 0 !important;

    @media (max-width: 1200px) {
      width: 250px;
    }

    @media (max-width: 757px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 1200px) {
    .task-list-page__sort-buttons.sort-buttons_first-block {
      display: grid;
      gap: 20px 10px;
      grid-template-columns: 1fr 1fr 1fr;
    }
    .search-block {
      .MuiFormControl-root {
        width: 100%;
      }
    }
    .MuiAutocomplete-root,
    .search-block .MuiFormControl-root,
    .MuiInputBase-root,
    .MuiFormControl-root,
    .picker {
      width: 100% !important;
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
    .task-list-page__sort-input {
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    .task-list-page__eddited-buttons {
      margin: 10px 0 15px;
    }
    .MuiInputBase-root {
      margin: 5px 0;
    }
    .task-list-page__sort-container {
      flex-direction: column;
    }
    .task-list-page__sort-buttons.sort-buttons_first-block {
      display: grid;
      grid-template-columns: 1fr 1fr;
      order: 1;
      column-gap: 10px;
    }
    .task-list-page__sort-input {
      &:first-child {
        grid-column: 1;
      }

      &:nth-child(2) {
        grid-column: 2;
        margin: 0;
      }
    }
    .picker {
      margin: 5px 0 !important;
    }
    .picker .input-mask-wrapper {
      width: 100%;
    }
    .date-picker-start {
      .modal-d {
        right: unset;
        left: 0;
      }
    }

    .pagination {
      margin-bottom: 0;
      margin: 0 auto 20px;
    }

    .pagination {
      li {
        font-size: 14px;
      }
    }
  }
}
