.hide-btn {
    width: 100%;
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    border-top: 1px solid #646c9a;
    cursor: pointer;
    color: #FFFFFF;
    white-space: nowrap;

    @media (max-width: 1024px) {
        display: none;
    }

    svg {
        margin: 20px;
    }
}
.svg-icon.svg-icon-primary {
    margin-right: 10px;

    svg g [fill] {
        fill: #3699FF !important;
    }
}

.kt-menu__link.mobile-none{
    display: none!important;

    @media (max-width: 1024px) {
        display: block!important;
    }
}

.kt-menu__link-icon-active {
    color: white!important;
}

.helper-menu-top {
    height: 44px; 
    width: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    &:hover {
        transition: all 0.3s;
        background-color: rgba(77, 89, 149, 0.06);
    }

    &__popup {
        width: 200px;
        position: absolute;
        top: 100%;
        right: 0;
        background-color: #ffffff;
        box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

        &__elem {
            white-space: nowrap;
            padding: 8px 15px!important;
            cursor: pointer;
            font-size: 1rem;
            font-weight: 500;
            color: #595d6e;
            border-bottom: 1px solid #f7f8fa;

            &:after {
                content: ''!important;
            }

            a {
                font-size: 1rem;
                font-weight: 500;
                color: #595d6e;
            }

            &:hover {
                color: #595d6e;

                a {
                    color: #595d6e;
                }
            }
        }
    }
}

.kt_aside-min {

}

.kt_aside-big {
    left: -240px!important;
}

//.kt-aside-dark .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-icon
.kt-aside-dark {
    .kt-menu__item--open > .kt-menu__link .kt-menu__link-icon,
    .kt-menu__link:hover .kt-menu__link-icon,
    .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-icon {
        color: #FFFFFF!important;
    }
    .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span,
    .kt-aside-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(.kt-menu__item--active):hover > .kt-menu__link .kt-menu__link-bullet.kt-menu__link-bullet--dot > span{
        background-color: #FFFFFF!important;
    }
}

.kt-aside__min {
    width: 75px!important;
    padding: 30px 0;

    .hide-btn {
        justify-content: center;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            padding: 9px 0;
            text-align: center;
        }

        .kt-menu__link-icon {
            color: #494b74;
            font-size: 17px;
        }
        .kt-menu__link-icon:hover, .kt-menu__item--active .kt-menu__link-icon {
            color: #FFFFFF;
        }

    }
}

@media (min-width: 1025px) {
    .kt-aside--fixed .kt-wrapper.min-bar {
        padding-left: 75px!important;
    }
}

.kt-header-menu-wrapper {
    left: auto!important;
    right: -295px!important;
    &.kt-header-menu-wrapper--on {
        left: auto!important;
        right: 0!important;
    }
}

.kt-header-menu-wrapper-close {
    left: auto!important;
    right: -25px!important;
}

.kt-header-menu-wrapper--on .kt-header-menu-wrapper-close {
    left: auto!important;
    right: 250px!important;
}

.grecaptcha-badge {
    display: none!important;
}