.kt-grid--animateContent-finished {
    transform: none !important;
}

.workouts-dnd {
    &__draggble {
        background-color: #fff;
        border-bottom: 1px solid lightgray;
        padding: 20px 0 20px 25px;
    }

    &__delete {
        margin-top: 7px;
        margin-left: 5px;
        height: 25px;
        cursor: pointer;
        transition: 0.2s;
        opacity: 0.7;

        &:hover {
            transform: scale(1.2);
            transition: 0.2s;
        }
    }
}
