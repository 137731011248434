.kt-grid--animateContent-finished {
    transform: none !important;
}

.workout-dnd {
    &__draggble {
        background-color: #fff;
        padding: 0 0 10px 25px;
    }

    &__delete {
        margin-top: 25px;
        margin-left: 5px;
        height: 25px;
        cursor: pointer;
        transition: 0.2s;
        opacity: 0.7;

        &:hover {
            transform: scale(1.2);
            transition: 0.2s;
        }
    }

    &__exercise-notes-button {
        cursor: pointer;
        margin-left: 5px;
        height: 20px;
    }

    &__exercise {
        border-bottom: 1px solid #dedede;
        padding-bottom: 10px;
    }

    &__exercise-delete {
        margin-right: 5px;
        height: 20px;
        cursor: pointer;
        transition: 0.2s;
        opacity: 0.7;

        &:hover {
            transform: scale(1.2);
            transition: 0.2s;
        }
    }

    &__exercise-header {
        margin-top: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 450px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    &__exercise-number {
        font-size: 13px;
        color: #000000bc;
        margin: 0 10px 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__exercise-header-title {
        width: 45%;
        overflow: hidden;
        text-overflow: ellipsis;

        @media (max-width: 450px) {
            width: 100%;
        }
    }

    &__exercise-header-actions {
        width: 55%;
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-wrap: wrap;

        @media (max-width: 450px) {
            justify-content: flex-start;
            width: 100%;
        }
    }

    &__exercise-header-note {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &__exercise-index {
        margin-right: 5px;
        font-size: 14px;
        color: #000000bc;
    }

    &__exercise-title {
        font-size: 13px;
        color: #000000bc;
    }

    &__exercise-sets-number {
        width: 45px;
        margin: 0 5px;
        font-size: 10px;

        input {
            border: 1px solid #c4c4c4;
            border-radius: 4px;
        }
    }

    &__exercise-sets-arrow {
        font-size: 16px;
        font-weight: 600;
        margin-left: 10px;
        cursor: pointer;
        transform: rotate(-90deg);
        transition: 0.2s;

        &_active {
            transform: rotate(90deg);
            transition: 0.2s;
        }
    }

    &__time-input {
        width: 60px;
    }

    .picker-wrap {
        margin: 0;

        .picker {
            height: auto;
            padding: 0;
            width: 60px;
        }
    }

    .exercise-add-set_btn {
        margin: 15px 0 5px;
        display: flex;
        border: none;
        appearance: unset;
        background: unset;
        padding: 0;
        svg {
            margin-right: 5px;
        }
    }
}
