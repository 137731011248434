.view-form {
    margin-top: 30px;
    padding-left: 10px;

    &__section {
        color: #3f51b5;
        font-weight: 600;
        font-size: 16px;
        margin: 40px 0 15px 0;
    }

    &__question {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 15px;

        .custom-radio {
            padding-top: 8px;
        }

        .MuiFormControl-root {
            width: calc(100% - 230px) !important;

            @media (max-width: 450px) {
                width: 100% !important;
            }
        }
    }

    &__question-text {
        margin-right: 20px;
        min-width: 200px;
        max-width: 50%;
        font-size: 14px;
        font-weight: 600;
    }
}
