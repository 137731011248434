.program-workouts {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  padding-left: 10px;
  border-radius: 4px;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__btn-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media (max-width: 450px) {
      justify-content: flex-start;
      margin-top: 15px;
    }
  }

  &__search {
    @media (max-width: 450px) {
      width: 100%;
      order: 1;
    }
  }

  &__sort-buttons {
    display: flex;

    @media (max-width: 450px) {
      flex-direction: column;
      order: 0;
    }
  }

  &__buttons {
    display: flex;
  }

  &__button {
    margin-left: 15px;

    @media (max-width: 450px) {
      margin-left: 0;
      margin-top: 15px;
      margin-right: 15px;
    }
  }

  .MuiOutlinedInput-root {
    height: 40px;
  }

  .MuiOutlinedInput-input {
    padding: 0;
  }

  &__dropdownmultiselect {
    height: 50px;

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
      padding: 0 0 0 4px;
      min-height: 40px;
      height: auto;
      position: absolute;
      top: 0;
      background-color: #fff;
    }

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
      z-index: 1;
    }

    .MuiInputLabel-outlined {
      z-index: 2 !important;
    }
  }

  .picker {
    height: 40px !important;
    margin-top: 0 !important;
  }

  &__dropdown {
    margin-right: 20px;
    width: 200px;

    @media screen and(max-width: 757px) {
      width: 100%;
    }

    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
      padding: 0 0 0 4px;
    }
  }

  .workouts-menu-dropdown {
    display: none;
  }

  @media screen and (max-width: 757px) {
    .pagination {
      margin-bottom: 0;
    }
    .hide-on-mob {
      display: none;
    }
    .workouts-menu-dropdown {
      display: flex;
      margin-left: auto;
      &.show > .btn-primary.dropdown-toggle {
        background-color: unset !important;
      }
    }
    .workouts-menu-dropdown .dropdown-toggle {
      transform: rotate(90deg);
      padding: 3px;
      background-color: unset !important;
      &::after,
      &::before {
        content: none;
      }
      .MuiSvgIcon-root {
        fill: #646c9a !important;
      }
    }
    .pagination-wrap {
      margin-top: 20px;
      padding-right: 10px;
    }
  }
}
