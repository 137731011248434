.exercise-data {
  margin-right: 25px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 20px;

  p {
    margin: 0;
  }

  &__page-title {
    font-size: 18px;
    font-weight: 700;
    position: relative;
  }

  &__page-title-text {
    margin-right: 40px;
  }

  &__close-button {
    cursor: pointer;
    font-weight: 600;
    color: gray;
    margin-right: 10px;
    position: absolute;
    top: 0;
    right: 5px;
  }

  &__video-title {
    margin-top: 15px;
    margin-bottom: 15px;
    color: #000;
    font-weight: 600;
    font-size: 16px;
  }

  &__video-container {
    position: relative;
    height: 300px;
    border: 1px solid #c7c8d4;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    background-color: #000000;
    & .overlay-image {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
      object-fit: contain;
      background-color: white;
    }

    @media (max-width: 450px) {
      height: 200px;
    }
  }

  iframe {
    height: 95% !important;
    min-width: 400px !important;
    max-width: 95% !important;

    @media (max-width: 450px) {
      min-width: 200px !important;
      width: 300px !important;
    }
  }

  &__description-wrapper {
    margin-top: 20px;
  }

  &__description-title {
    font-size: 16px;
    font-weight: 600;
    color: #000000bc;
  }

  &__description-text {
    font-size: 15px;
    color: #000000bc;
  }

  &__data-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  &__data-container {
    display: flex;
    flex-direction: column;
    min-width: 47%;

    &:first-child {
      margin-right: 20px;
    }
  }

  &__data-block {
    font-size: 15px;
    color: #000000bc;
    margin-bottom: 20px;
  }

  &__data-block-title {
    font-weight: 600;
    margin-bottom: 10px;
  }
}
