.mass-create-profile-title {
    font-size: 18px;
    font-weight: 500;
    margin: 15px 0 20px 20px;
}

.mass-create-profile-progress {
    overflow: hidden;
    margin-left: 20px;
    height: 12px;
    width: 90%;
    border-radius: 15px;
    background-color: #f5f5f5;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);

    &__value {
        height: 100%;
        border-radius: 15px;
        background-color: #5d78ff;
    }
}

.mass-create-profile-count {
    display: flex;
    margin: 20px;
    gap: 50px;

    &__text {
        font-size: 16px;
        font-weight: 500;
    }

    &__err-text {
        font-size: 16px;
        font-weight: 500;
        color: red;
    }
}

.mass-create-profile-count__email {
    font-size: 14px;
    margin-top: 5px;
    margin-left: 20px;
    color: red;
}

.mass-create-profile-dropdown {
    .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
        padding: 9px !important;
    }
}
