.calendar-page {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
  padding: 25px;
  border-radius: 4px;

  &__sort-input {
    margin-right: 20px;
  }

  .MuiOutlinedInput-root {
    height: 43px;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
    .MuiAutocomplete-input:first-child {
    padding-left: 15px !important;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    width: 200px;
    min-height: 43px;
    padding: 0 0 0 0 !important;
    height: auto;
    background-color: #fff;
  }

  .MuiTab-wrapper {
    font-size: 12px;
    font-weight: 700;
  }

  .create-event-btn {
    margin-left: 20px;

    @media (max-width: 450pc) {
      margin-left: 0;
    }
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-direction: column;
      gap: 15px;
    }

    &__block {
      display: flex;
      gap: 10px;

      .calendar-page__sort-input {
        margin-right: 0;
      }

      @media screen and (max-width: 600px) {
        margin-top: 5px;
        display: grid;
        grid-template-columns: 1fr 1fr;

        .MuiInputBase-root {
          width: 100%;
        }

        .calendar-page__sort-input {
          grid-column: 1;
          grid-row: 2;

          .MuiAutocomplete-root {
            width: 100% !important;
          }
        }
        .header__dropdown {
          grid-column: 2;
          grid-row: 2;
          width: 100%;
        }
        .create-event-btn {
          grid-column: 1;
          height: auto !important;
          width: fit-content;
          margin-bottom: 10px;
          grid-row: 1;
        }
      }
    }

    &__dropdown {
      width: 200px;

      .MuiOutlinedInput-input {
        padding: 0 !important;
      }

      .MuiOutlinedInput-root {
        height: 40px;
      }
    }
  }

  .subheader {
    margin-top: 20px;
    min-height: 50px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: flex-start;

    @media (max-width: 757px) {
      display: flex;
      flex-direction: column;
    }

    &__block {
      display: flex;
      height: 100%;
      align-items: center;

      &:last-child {
        gap: 10px;
        .btn:last-child {
          margin-left: 0;
        }
      }

      @media (max-width: 757px) {
        justify-content: flex-start !important;
        margin-top: 10px;
        order: 1;
        width: 100% !important;

        &:last-child {
          order: 0;
          margin-bottom: 10px;
          margin-top: 0;
        }
      }
    }

    &__second-block {
      font-size: 20px;
      font-weight: 600;
    }

    &__datepicker {
      height: 43px;
      margin: 0 10px 0 0;

      .picker {
        margin: 0;
        height: 43px;
      }

      @media screen and (max-width: 450px) {
        width: 100%;
        .picker {
          width: 100% !important;
        }
      }
    }
  }

  .today-datepicker {
    .picker {
      margin: 0 !important;
    }
  }

  .agenda-datepickers {
    display: flex;

    @media (max-width: 450px) {
      flex-direction: column;
    }
  }

  .agenda-datepicker {
    display: flex;
    align-items: center;
    margin-right: 20px;

    @media (max-width: 450px) {
      margin-top: 10px;
    }

    &__text {
      font-size: 14px;
      white-space: nowrap;

      @media (max-width: 450px) {
        width: 80px;
      }
    }

    &__input {
      margin-left: 10px;
      width: 130px;

      @media (max-width: 450px) {
        width: 200px;
      }
    }
  }

  .sort-arrow {
    opacity: 0.7;
    transition: all 0.3s ease-out;

    &.active {
      opacity: 1;
    }

    &.reverse {
      transform: rotate(180deg);
    }
  }

  .modal-d.bottom {
    @media (max-width: 1024px) {
      right: auto;
      left: 0;
    }
  }

  .new-calendar__table-container {
    overflow: auto;
    width: 100%;
    height: calc(100vh - 400px);

    @media screen and (max-width: 767px) {
      height: calc(100vh - 150px);
    }

    table {
      width: 100%;

      th {
        border-right: 1px solid lightgrey;
        border-bottom: 1px solid lightgrey;
        height: 60px;
        text-align: center;
        padding: 10px;
        overflow: hidden;
        text-overflow: ellipsis;
        min-width: 100px;
        position: sticky;
        top: 0;

        background-color: #f4f4f4;
      }
      th:first-child {
        left: 0;
        z-index: 10;
      }
      td {
        border-right: 1px solid lightgrey;
        border-bottom: 1px solid lightgrey;
        height: 60px;
        padding: 10px;
        min-width: 100px;
      }
      td:first-child {
        position: sticky;
        left: 0;
        z-index: 9;
        background-color: #f4f4f4;
      }
    }
  }

  .current {
    background-color: #eef1ff;
  }

  .subheader {
    margin-bottom: 20px;
  }
}
