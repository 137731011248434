.create-workout {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
    border-radius: 4px;
    margin-top: 25px;

    &__page-title {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 20px;
        margin-left: 25px;
        padding-top: 25px;
    }

    &__page-header {
        margin-left: 25px;
    }

    &__textfield {
        width: 100%;
        padding-top: 15px;
    }

    &__body {
        display: flex;
        padding-bottom: 30px;

        @media (max-width: 840px) {
            flex-direction: column;
        }
    }

    &__first-block {
        width: 100%;

        @media (max-width: 840px) {
            padding-right: 25px;
        }
    }

    &__row-container {
        display: flex;
        justify-content: space-between;

        @media (max-width: 450px) {
            flex-direction: column;
            justify-content: space-between;
            height: 210px;
        }
    }

    .MuiOutlinedInput-input {
        padding: 14px 14px !important;
    }

    &__datepicker {
        margin-right: 20px;

        @media(max-width: 450px) {
            margin-right: 0;
        }
    }

    &__dropdown {
        height: 70px;
        display: flex;
        justify-content: space-between;

        @media (max-width: 450px) {
            min-width: auto;
            width: 100%;
        }

        & > div {
            @media (max-width: 450px) {
                width: 100%;
            }
        }

        input {
            width: 100px !important;
        }

        .MuiAutocomplete-input {
            padding: 5px !important;
        }
    }

    &__time-input {
        width: 150px;

        @media (max-width: 450px) {
            width: calc(100%);
        }
    }

    &__section-button {
        margin: 25px 0 25px 25px;
    }

    &__second-block {
        width: 100%;
        min-height: 404px;
        margin-bottom: 25px;
        padding-left: 25px;
    }

    &__no-exercise {
        height: 100%;
        border: 1px solid #c4c4c4;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 25px;

        @media (max-width: 840px) {
            min-height: 404px;
        }
    }

    .picker {
        margin: 0 !important;
    }
}
