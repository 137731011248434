.edit-exercise {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
    border-radius: 4px;
    overflow-y: auto;
    padding: 25px;

    &__close-button {
        position: absolute;
        top: 9px;
        right: 20px;
        cursor: pointer;
        color: grey;
        font-size: 2.5em;
    }

    &__page-title {
        font-size: 18px;
        font-weight: 700;
    }

    &__page-body {
        margin-top: 44px;
    }

    &__checkbox-title {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
    }

    &__checkboxes {
        margin-bottom: 15px;
        margin-left: -10px;
        display: flex;
    }

    &__access-dropdown {
        height: 70px;

        .MuiAutocomplete-input {
            padding: 5px !important;
        }
    }

    &__textfield {
        width: 100%;
        margin-bottom: 16px;
    }

    &__textarea {
        width: 100%;
        margin-bottom: 16px;

        .MuiOutlinedInput-input {
            height: 100px !important;
        }
    }

    &__dropdown {
        height: 70px;

        .MuiAutocomplete-input {
            padding: 5px !important;
        }
    }

    &__video-container {
        height: 303px;
        border: 1px solid #c7c8d4;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 21px;
        background-color: #000000;
    }
}
