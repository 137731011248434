.forms-list {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
    padding: 25px;
    border-radius: 4px;

    &__page-title {
        font-size: 20px;
        font-weight: 600;
    }

    &__btn-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }

    &__sort-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        margin-bottom: 10px;
        gap: 20px;
    }

    &__sort-wrapper {
        display: flex;

        @media (max-width: 450px) {
            flex-direction: column;
        }
    }

    &__filter-dropdown {
        width: 250px;

        @media (max-width: 450px) {
            width: 150px;
        }
    }

    &__filter-buttons {
        display: flex;
        
        @media (max-width: 450px) {
            margin-top: 20px;
        }
    }

    &__filter-button {
        margin-left: 15px;

        &:first-child {
            @media (max-width: 450px) {
                margin-left: 0;
            }
        }
    }

    .MuiOutlinedInput-root {
        height: 40px;
    }

    .forms-list__filter-dropdown {
        .MuiOutlinedInput-input {
            padding: 0 !important;
        }
    }

    &__header-list {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap; 
        
        .btn-container {
            position: relative;
        }
    }

    .pagination-wrap {
        margin-top: 15px;

        .pagination {
            margin: 0;
        }
    }

}
