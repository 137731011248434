.custom-radio.column {
    flex-direction: column;
    align-items: flex-start;
}

.custom-radio {
    display: flex;
    align-items: center;
    position: relative;

    &__elem {
        display: flex;
        margin-right: 37px;
        cursor: pointer;
    }

    &__elem.column {
        margin-top: 15px;

        &:first-child {
            margin-top: 0;
        }
    }

    &__checkbox {
        min-width: 16px;
        width: 16px;
        height: 16px;
        border: 1px solid #bdbdbd;
        border-radius: 50%;
        margin-right: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        &_checked {
            border: 1px solid #393939;
        }

        &_error {
            border: 1px solid red;
            opacity: 0.7;
        }
    }

    &__dot {
        background-color: #393939;
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }

    &__title {
        font-size: 13px;
        color: #7388a7;
        font-weight: 400;
    }

    &__err-container {
        min-width: 160px;
        position: absolute;
        top: 100%;
        left: 0;
        border-radius: 3px;
        padding: 7px;
        opacity: 1;
        transition: 0.5s;
        color: red;
    }

    &__err-text {
        font-size: 0.75rem;
        white-space: nowrap;
        font-weight: 400;
    }
}
