.form-view-modal {
    margin-top: 30px;

    .modal-content {
        width: 600px;
    }

    &__title {
        margin-right: 50px !important;
    }

    &__section {
        color: #3f51b5;
        font-weight: 600;
        font-size: 16px;
        margin: 24px 0 15px 0;
    }

    &__question {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 15px;

        .MuiFormControl-root {
            width: calc(100% - 230px);

            @media (max-width: 450px) {
                width: 100%;
            }
        }

        .custom-radio {
            padding-top: 8px;
        }
    }

    &__question-text {
        margin-right: 20px;
        min-width: 200px;
        font-size: 14px;
        font-weight: 600;
    }
}
