.create-report-page {
  .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 2px 35px 2px 10px;
    min-height: 36px;
  }

  .MuiAutocomplete-root {
    width: 150px !important;
  }

  .makeStyles-root-5 {
    width: 150px !important;
  }

  .MuiChip-root {
    height: 20px !important;
  }

  .picker {
    height: 36px !important;
  }

  .top-buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .page-title {
    font-size: 25px;
    font-weight: 500;
  }

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .body-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
  }

  .column {
    &:first-child {
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;
    }

    &:last-child {
    }
  }

  .elem-container {
    min-width: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 10px 0px;

    @media (max-width: 750px) {
      width: 300px !important;
    }

    &.large {
      margin: 10px;
    }

    .MuiOutlinedInput-input {
      padding: 5px 14px !important;
    }
  }

  .date-container {
    // width: 250px;
    display: flex;
    justify-content: space-between;
    margin: 10px 10px 0px;

    .elem-title {
      top: 10px;
    }
  }

  .elem-title {
    min-width: 83px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
    position: relative;
    top: 10px;
    margin: -19px 15px 0 0;

    @media (max-width: 1550px) {
      min-width: 135px;
    }

    &.users {
      min-width: 50px;

      @media (max-width: 1550px) {
        min-width: 135px;
      }
    }

    // @media (max-width: 1600px) {
    //   min-width: 124px;
    // }
  }

  .main-wrapper {
    margin-top: 15px;
    margin-bottom: 45px;
  }
  .elem-container--margin {
    margin-left: 100px;
  }
}
@media screen and (max-width: 500px) {
  .elem-container--margin {
    margin-left: 10px !important;
  }

  .create-report-page {
    .page-title {
      font-size: 20px;
      font-weight: 600;
      margin: 5px;
    }

    .top-buttons-wrapper {
      display: block;
    }

    .button-wrapper {
      display: block;
    }

    .elem-container,
    .date-container {
      width: 150px;
      margin-left: 0 !important;
    }

    .elem-title {
      font-size: 14px;
      width: 90px;
    }

    .MuiAutocomplete-root.MuiAutocomplete-hasClearIcon.MuiAutocomplete-hasPopupIcon,
    .ffiuIu .picker {
      width: 160px !important;
    }
  }
}

.report-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  &.groups_and_nfo {
    @media (max-width: 1550px) {
      flex-direction: row;
    }
    @media (max-width: 1400px) {
      flex-direction: column;
    }
  }

  @media (max-width: 1550px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .MuiAutocomplete-root {
    width: 160px !important;
  }

  .report-row-in-row {
    display: flex;

    @media (max-width: 750px) {
      flex-direction: column;
    }
  }
}
