.calendar-agenda__table{
    tbody {
        display: block;
        max-height: 300px;
        overflow: auto;
    }
    thead, tbody tr {
        display: table;
        width: 800px;
        table-layout: fixed;/* even columns width , fix width of table too*/
        @media(max-width: 2000px){
            width: 750px;
        }
        @media(max-width: 1900px){
            width: 700px;
        }

        @media(max-width: 1800px){
            width: 670px;
        }

        @media(max-width: 1750px){
            width: 600px;
        }

        @media(max-width: 1600px){
            width: 570px;
        }

        @media(max-width: 1550px){
            width: 500px;
        }

        @media(max-width: 1400px){
            width: 470px;
        }

        @media(max-width: 1025px){
            width: 900px;
        }
    }
  
}

