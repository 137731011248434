.exercises-list {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
    padding: 25px;
    border-radius: 4px;

    &__btn-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        @media (max-width: 450px) {
            justify-content: flex-start;
            margin-top: 15px;
        }
    }

    &__search.exercises-list__sort-input {
        margin-left: auto;
        order: 1;

        .MuiInputBase-root {
            width: auto !important;
        }

        @media (max-width: 850px) {
            width: 100%;
            margin-left: 0;
            order: 0;
        }
    }

    &__sort-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        margin-bottom: 10px;

        @media (max-width: 850px) {
            flex-direction: column;
            margin-top: 15px;

            & > div {
                margin-bottom: 15px;
            }
        }
    }

    &__sort-inputs {
        display: flex;
        gap: 10px;

        .MuiInputBase-root, .MuiAutocomplete-root {
            width: 200px !important;
        }

        @media (max-width: 810px) {
            width: 100% !important;
            display: grid;
            gap: 10px;
            grid-template-columns: .9fr 1fr;

            .MuiInputBase-root, 
            .MuiAutocomplete-root, 
            .multiple-select-checkmarks, 
            .MuiFormControl-root {
                width: 100% !important;
            }
        }
    }

    &__sort-input {
        @media (max-width: 820px) {
            margin-left: 0;

            &:nth-child(3) {
                grid-column: 1 / 2;
                grid-row: 2;
            }
        }
    }

    &__sort-buttons {
        width: 100%;
        @media (max-width: 850px) {
            display: flex;
            flex-direction: column;
            order: 2;
        }
    }

    &__buttons {
        display: flex;
        margin-top: 15px;
    }

    &__button {
        margin-left: 15px;

        &:first-child {
            margin-left: 0;
        }

        @media (max-width: 450px) {
            margin-left: 0;
            margin-top: 15px;
            margin-right: 15px;
        }
    }

    .MuiOutlinedInput-root {
        height: 40px;
    }

    .MuiOutlinedInput-input {
        padding: 0;
    }

    .MuiAutocomplete-input {
        padding: 5px !important;
    }

    &__header-list {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap; 
        
        .btn-container {
            position: relative;

            @media screen and (max-width: 415px) {
                margin-top: 15px;
            }
        }
    }

    .pagination-wrap {
        margin-top: 15px;

        .pagination {
            margin: 0;
        }
    }

}
