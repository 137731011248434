.create-exercise {
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 0px 13px 0px rgba(82, 63, 105, 0.05);
    border-radius: 4px;
    overflow-y: auto;
    padding: 25px;

    &__page-title {
        font-size: 18px;
        font-weight: 700;
    }

    &__page-body {
        display: flex;
        margin-top: 44px;

        @media (max-width: 450px) {
            flex-direction: column;
        }
    }

    &__page-block {
        width: 45%;

        @media (max-width: 450px) {
            width: 100%;
        }
    }

    &__page-block2 {
        margin-left: 28px;

        @media (max-width: 450px) {
            margin-left: 0%;
        }
    }

    &__checkbox-title {
        font-size: 14px;
        font-weight: 600;
        color: #000000;
    }

    &__checkboxes-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__checkboxes {
        margin-bottom: 15px;
        margin-left: -10px;
        display: flex;
    }

    &__access-dropdown {
        height: 70px;
        margin-left: 0;

        .MuiAutocomplete-input {
            padding: 5px !important;
        }
    }

    &__textfield {
        height: 70px;
        width: 100%;
    }

    &__textarea {
        width: 100%;
        margin-bottom: 16px;

        .MuiOutlinedInput-input {
            height: 100px !important;
        }
    }

    &__dropdown {
        height: 70px;

        .MuiAutocomplete-input {
            padding: 5px !important;
        }
    }

    &__video-container {
        height: 303px;
        border: 1px solid #c7c8d4;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 21px;
        background-color: #000000;
    }

    &__video-container-empty {
        height: 303px;
        border: 1px solid #c7c8d4;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 21px;
        background-color: #6c6868;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        color: #fff;
        font-weight: 600;
        padding: 30px;
        text-align: center;
    }
}
