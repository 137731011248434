.dashboard-page {
    width: 100%;
    border-radius: 4px;
    display: flex;

    @media (max-width: 1024px) {
        flex-direction: column;
    }

    .first-block {
        width: 50%;
        margin-right: 20px;

        @media (max-width: 1024px) {
            width: 100%;
        }
    }

    .second-block {
        width: 50%;

        @media (max-width: 1024px) {
            width: 100%;
        }
    }

    .title {
        font-size: 20px;
        font-weight: 600;
        margin: 15px 20px 15px 0;

        @media screen and (max-width: 768px) {
            white-space: nowrap;
        }
    }

    .header-wrapper {
        width: 100%;
        display: flex;
        align-items: center;

        @media screen and (max-width: 570px) {
            display: grid;
            gap: 10px;
            grid-template-columns: 1fr 1fr;
        }
    }

    .header-dropdown {
        width: 200px;
        position: relative;
        height: 40px;
        margin-right: 20px;

        .MuiOutlinedInput-root {
            min-height: 40px;
            padding: 0px !important;
            padding-left: 10px !important;
            position: absolute;
            background-color: #fff;
            // width: 200px;
        }
        .MuiAutocomplete-root {
            width: 200px !important;
        }

        @media screen and (max-width: 570px) {
            grid-row: 2;
            width: 100%;

            .MuiAutocomplete-root {
                width: 100% !important;
            }
        }
    }

    .header-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .header-block {
        align-items: center;
        padding: 10px 5px;
        box-shadow: 0px 0px 15px 0px rgba(82, 63, 105, 0.20);
        width: 49%;
        height: 93px;
        margin-bottom: 15px;
        background-color: #fff;
        border-radius: 4px;

        @media (max-width: 450px) {
            padding: 0;
            justify-content: center;
        }

        &__number{
            font-family: 'Poppins';
            font-weight: 500;
            font-size: 25px;
            color: #ff722c;
            text-align: center;

            @media (max-width: 450px) {
                width: auto;
            }
        }

        &__text{
            text-transform: uppercase;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #595d6e;
            text-align: center;
            
            @media (max-width: 450px) {
                margin-left: 10px;
            }
        }
    }

    .table-block {
        box-shadow: 0px 0px 15px 0px rgba(82, 63, 105, 0.20);
        width: 100%;
        padding: 15px;
        background-color: #fff;
        border-radius: 4px;

        &__header {
            display: flex;
            justify-content: space-between;

            @media (max-width: 450px) {
                flex-direction: column;
            }
        }

        &__header-dropdown {
            .MuiOutlinedInput-input {
                padding: 0 !important;
            }

            .MuiOutlinedInput-root {
                height: 40px;
            }
        }

        &__tabs {
            display: flex;
            justify-content: space-between;

            @media (max-width: 450px) {
                flex-direction: column;
            }
        }

        &__status-dropdown {
            width: 200px;
            height: 50px;

            @media (max-width: 450px) {
                margin-top: 20px;
            }

            .MuiOutlinedInput-root {
                min-height: 40px;
                padding: 0px !important;
                padding-left: 10px !important;
                position: absolute;
                background-color: #fff;
                width: 200px;
            }
        }
    }

    .MuiOutlinedInput-input {
        padding: 0 !important;
    }

    .MuiTab-wrapper {
        font-size: 12px;
        font-weight: 700;
    }

    .picker {
        margin: 0 !important;
    }
}
