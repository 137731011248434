.agenda-tab {
  margin-top: 20px;
  margin-bottom: 20px;

  .title {
    font-size: 16px;
  }

  &__buttons {
    display: flex;
    height: 40px;
    align-items: center;
  }

  @media screen and (max-width: 767px) {
    table {
      min-width: 650px;
      thead th {
        position: sticky;
        top: 0;
        background-color: #f4f4f4;
        z-index: 2;
        padding: 8px 10px;
        font-size: 14px;
        height: 50px;
      }
      tbody td:first-child,
      thead th:first-child {
        width: 48px;
      }
      tbody td:nth-child(2),
      thead th:nth-child(2) {
        position: sticky;
        left: 0;
      }
      tbody td:first-child,
      tbody td:nth-child(2) {
        z-index: 10;
        background-color: #f9f9f9;
      }
      thead th:first-child,
      thead th:nth-child(2) {
        z-index: 11;
      }
      .th {
        height: 50px;
        font-weight: 500;
        cursor: pointer;
        padding: 8px 10px;
        font-size: 14px;
      }
    }
  }
}
